.grid-box
{
    float: left;
    overflow-y: scroll;
    height:88vh;
    width: 100%;
    overflow-x: hidden;
  /* display: -webkit-flex;
  -webkit-align-items: flex-start;
  -moz-align-items: center; */
  /* align-items: flex-start; */
  /* -webkit-justify-content: center; */
  /* justify-content: center; */
  /* flex-direction: row;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-content: flex-end;
  -moz-align-content: flex-end;
  align-content: flex-start; */
  /* margin-left: -15px; */
	/* margin-bottom: 15px; */
}
.grid-box .grid-1
{
    position: relative;
    float: left;
    width: 100%;
    transition: .3s linear all;
}
.grid-box .grid-2
{
    position: relative;
    float: left;
    width: calc(100% / 2);
    transition: .3s linear all;
}
.grid-box .grid-3
{
    position: relative;
    float: left;
    width: calc(100% / 3);
    transition: .3s linear all;
}
.grid-box .grid-4 {
    position: relative;
    float: left;
    width: calc(100% / 4);
    -webkit-transition: .3s linear all;
    transition: .3s linear all;
}
.grid-box .grid-5
{
    position: relative;
    float: left;
    width: calc(100% / 5);
    transition: .3s linear all;
}
.grid-box .grid-6
{
	position: relative;
	float: left;
	width: calc(100% / 6);
	transition: .3s linear all;
}

@media only screen and (max-width: 1600px)
{
    .grid-box .grid-5
    {
        position: relative;
        float: left;
        width: calc(100% / 5);
        transition: .3s linear all;
    }
}
@media only screen and (max-width: 1300px)
{
    .grid-box .grid-4 {
        position: relative;
        float: left;
        width: calc(100% / 4);
        -webkit-transition: .3s linear all;
        transition: .3s linear all;
    }
}
@media only screen and (max-width: 1150px)
{
    .grid-box .grid-3
    {
        position: relative;
        float: left;
        width: calc(calc(100% / 3) - 15px);
        margin-right: 15px;
        margin-bottom: 15px;
        transition: .3s linear all;
    }
}
@media only screen and (max-width: 960px)
{
    .grid-box .grid-2
    {
        position: relative;
        float: left;
        width: calc(calc(100% / 2) - 15px);
        margin-right: 15px;
        margin-bottom: 15px;
        transition: .3s linear all;
    }
}
@media only screen and (max-width: 620px)
{
    .grid-box .grid-1
    {
        position: relative;
        float: left;
        width: calc(100% - 15px);
        margin-right: 15px;
        margin-bottom: 15px;
        transition: .3s linear all;
    }
}